/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  color: rgb(0, 0, 0);
}

body [class^='mat-'],
body [class*=' mat-'] {
  font-family: 'Montserrat', sans-serif; 
}

.smartbot {
  font-family: 'Poppins', sans-serif;
  color: rgb(0, 29, 83);
}

.mat-button-base {
  font-weight: 700;
}

body .material-icons {
  font-family: 'Material Icons';
}

body {
  margin: 0;
}

h2 {
  font-weight: 500;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mtp-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}


.pl-0 {
  padding-left: 0 !important;
}

w-100 {
  width: 100%;
}

end {
  justify-content: end;
}

.mat-headline {
  font-weight: 700;
}

dashboard mat-card {
  margin-top: 2px;

  @media (min-width: 992px) {
    margin-top: 10px;
    min-height: 100px;
    margin-bottom: 1rem;
  }
}

mat-card {
  margin: 20px 0;
}
.mat-card-title {
  font-weight: 700;
}

form mat-form-field {
  width: 100%;
}

form input {
  width: 100%;
}

.ww-100 {
  width: 100%;
}
